import { Backdrop, CircularProgress } from "@material-ui/core";
import React from "react";

const Loader = (props:any) => {
  return (
    <Backdrop open={props.isLoading} style={{ zIndex: 9999 }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loader;
