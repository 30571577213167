import Web3 from "web3";
import { Contract } from "@ethersproject/contracts";
const web3 = new Web3();

export const tokenBalance = (userAddress: string, tokenContract: Contract) => {
    //@ts-ignore

    return new Promise((resolve, reject) => {
        try {
            tokenContract
                .balanceOf(userAddress)
                .then((result: any) => {
                    let amount = (result / 10 ** 18).toFixed(3).toString();
                    resolve(amount);
                })
                .catch((error: Error) => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export const allowance = (
    address: string,
    tokenContract: Contract,
    mainContract: string,
) => {
    //@ts-ignore

    return new Promise((resolve, reject) => {
        try {
            tokenContract
                .allowance(address, mainContract)
                .then((data: any) => {
                    let approveToken = web3.utils.fromWei(`${data}`, "ether");
                    resolve(Number(approveToken));
                })
                .catch((error: Error) => {
                    reject(error);
                });
        } catch (error) {}
    });
};

export const approveAllowance = (
    address: string,
    value: number,
    tokenContract: Contract,
    mainContract: string,
) => {
    //@ts-ignore

    return new Promise((resolve, reject) => {
        try {
            tokenContract
                .approve(
                    mainContract,
                    web3.utils.toWei(`100000000000`, "ether"),
                    {
                        gasLimit: web3.utils.toHex(1200000),
                        gasPrice: web3.utils.toHex(
                            web3.utils.toWei("7", "gwei"),
                        ),
                    },
                )
                .then((data: any) => {
                    resolve(data);
                })
                .catch((error: Error) => {
                    reject(error);
                });
        } catch (error) {
            reject(error);
        }
    });
};
