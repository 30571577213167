import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { INFURA_KEY } from "../app.config";
import WalletConnectProvider from "@walletconnect/ethereum-provider";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";

export const injected = new InjectedConnector({
    supportedChainIds: [4],
});

export const CoinbaseWallet = new WalletLinkConnector({
    url: `https://mainnet.infura.io/v3/${INFURA_KEY}`,

    appName: "Paraacrypro",
    supportedChainIds: [4],
});

export const WalletConnect = new WalletConnectConnector({
    rpc: {
        4: `https://rinkeby.infura.io/v3/${INFURA_KEY}`,
    },
    bridge: "https://bridge.walletconnect.org",
    qrcode: true,
    chainId: 4,
});
