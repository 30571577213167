import { SWAP_TOKEN_CONTRACT } from "../../app.config";
import Web3 from "web3";
import { Contract } from "@ethersproject/contracts";
const web3 = new Web3();

// @ts-ignore
// let swapContract = new web3.eth.Contract(OwnableAbi, contractAddress);

export const swapTokenToBnb = (
    payableAmount: number,
    estimateBnb: number,
    userAddress: string,
    swapContract: Contract,
) => {
    return new Promise((resolve, reject) => {
        try {
            swapContract
                .tokenToBnbSwapInput(
                    web3.utils.toWei(`${Number(payableAmount)}`, "ether"),
                    web3.utils.toWei(`${Number(estimateBnb)}`, "ether"),
                    {
                        gasLimit: web3.utils.toHex(120000),
                        gasPrice: web3.utils.toHex(
                            web3.utils.toWei("7", "gwei"),
                        ),
                    },
                )
                .then((data: any) => {
                    resolve(data);
                })
                .catch((error: Error) => {
                    reject(error);
                });
        } catch (error) {
            reject(error);
        }
    });
};

export const swapBnbToToken = (
    tokenSold: number,
    minEstimate: number,
    userAddress: string,
    swapContract: Contract,
) => {
    // @ts-ignore
    return new Promise(async (resolve, reject) => {
        console.log("minEstimate: ", minEstimate);
        console.log("tokenSold: ", tokenSold);
        try {
            await swapContract
                .bnbToTokenSwapInput(
                    web3.utils.toWei(`${minEstimate}`, "ether"),
                    web3.utils.toWei(`${tokenSold}`, "ether"),
                    {
                        gasLimit: web3.utils.toHex(120000),
                        gasPrice: web3.utils.toHex(
                            web3.utils.toWei("7", "gwei"),
                        ),
                    },
                )
                .then((data: any) => {
                    console.log(
                        "%c 🧀 data: ",
                        "font-size:20px;background-color: #ED9EC7;color:#fff;",
                        data,
                    );
                    resolve(data);
                })
                .catch((error: Error) => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

// export const tokenBalance = (userAddress) => {
//   const swapContract = new web3.eth.Contract(OwnableAbi, contractAddress);
//   return new Promise((resolve, reject) => {
//     try {
//       swapContract.methods
//         .balanceOf(userAddress)
//         .call()
//         .then((result) => {
//           let amount = (result / 10 ** 18).toFixed(3).toString();
//           resolve(amount);
//         })
//         .catch((error) => reject(error));
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const bnbBalance = (amount) => {
//     const swapContract = new web3.eth.Contract(OwnableAbi, contractAddress);
//     return new Promise((resolve, reject) => {
//       try {
//         swapContract.methods
//           .getBnbToTokenInputPrice(amount)
//           .call()
//           .then((data) => {
//             resolve(data);
//           })
//           .catch((error) => reject(error));
//       } catch (error) {
//         reject(error);
//       }
//     });
//   };
export const getTokenFromBnb = (BnbAmount: string, swapContract: Contract) => {
    // @ts-ignore
    return new Promise((resolve, reject) => {
        try {
            swapContract
                .getBnbToTokenInputPrice(web3.utils.toWei(BnbAmount, "ether"))
                .then((data: any) => {
                    let bigNumber = web3.utils.toBN(data);
                    resolve(web3.utils.fromWei(bigNumber, "ether"));
                })
                .catch((error: Error) => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export const getBNBFromToken = (BnbAmount: string, swapContract: Contract) => {
    // @ts-ignore
    return new Promise((resolve, reject) => {
        try {
            swapContract
                .getBnbToTokenInputPrice(web3.utils.toWei(BnbAmount, "ether"))
                .then((data: any) => {
                    let bigNumber = web3.utils.toBN(data);
                    resolve(web3.utils.fromWei(bigNumber, "ether"));
                })
                .catch((error: Error) => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};
export const getBnbFromToken = (token: string, swapContract: Contract) => {
    // @ts-ignore
    return new Promise((resolve, reject) => {
        try {
            swapContract
                .getTokenToBnbInputPrice(web3.utils.toWei(token, "ether"))
                .then((data: any) => {
                    resolve(data);
                })
                .catch((error: Error) => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export const getInputPrice = (
    inputAmount: number,
    inputReserve: number,
    outputReserve: number,
    swapContract: Contract,
) => {
    // console.log('swapContract: ', swapContract);
    // console.log('outputReserve: ', outputReserve);
    // console.log('inputReserve: ', inputReserve);
    // console.log('inputAmount: ', inputAmount);
    return new Promise((resolve, reject) => {
        try {
            swapContract
                .getInputPrice(
                    web3.utils.toWei(`${inputAmount}`, "ether"),
                    // inputReserve,
                    // outputReserve,
                    web3.utils.toWei(`${inputReserve}`, "ether"),
                    web3.utils.toWei(`${outputReserve}`, "ether"),
                )
                .then((data: any) => {
                    // console.log('data: ', data);
                    resolve(
                        web3.utils.fromWei(
                            web3.utils.toBN(data).toString(),
                            "ether",
                        ),
                    );
                })
                .catch((error: Error) => {
                    reject(error);
                });
        } catch (error) {
            reject(error);
        }
    });
};

export const getContractBusdBalance = (swapContract: Contract) => {
    return new Promise((resolve, reject) => {
        try {
            swapContract
                .busdBalance()
                .then((data: any) => {
                    const bn = web3.utils.toBN(data).toString();
                    resolve(web3.utils.fromWei(bn, "ether"));
                })
                .catch((error: Error) => {
                    reject(error);
                });
        } catch (error) {
            reject(error);
        }
    });
};
export const getContractTokenBalance = (swapContract: Contract) => {
    return new Promise((resolve, reject) => {
        try {
            swapContract
                .tokenBalance()
                .then((data: any) => {
                    const bn = web3.utils.toBN(data).toString();
                    resolve(web3.utils.fromWei(bn, "ether"));
                })
                .catch((error: Error) => {
                    reject(error);
                });
        } catch (error) {
            reject(error);
        }
    });
};
