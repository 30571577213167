export const INFURA_KEY = "bc00870f8acd437f81ffdcf8b3b3c966";

export const SWAP_TOKEN_CONTRACT =
    "0xE9aA85D9142A78b65244023Ca831C9A3b036e99d"; /*  "0x1b4D542D8b1073F39cB8F54Cb01e1C233901976B"; */
export const ERC_20_TOKEN =
    "0x5ebbb1B7A0bb7849CA0A7D3b3F0dC696c3A47628"; /* "0x7981691F1c1e88A0Aaf86DaC64eFe8d8cFb37997" */
export const ERC_20_BUSD_CONTRACT =
    "0x783b5af21fd4e99cf98ebe469a2f63dcfde6c738"; /* "0x783b5aF21Fd4e99cF98ebE469A2F63dcfdE6C738" */
export const RPC_URL =
    "https://rinkeby.infura.io/v3/bc00870f8acd437f81ffdcf8b3b3c966";

export const TOLERANCE_PERCENTAGE = 3;
