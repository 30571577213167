// this is a Component index file and Provide a Component
// import SwapPage from '../../components/swap';

// export { SwapPage };

import styled from '@emotion/styled';
import { Container, Grid, useMediaQuery } from '@mui/material';
import React, { Component } from 'react';
import { useSelector } from 'react-redux';
import { AppBar, AppFooter, CurrencyModal, ReferralModal } from '../../components';
import HomePage from '../HomePage';
import SwapPageRoot from './SwapPageRoot';
import {
  BrowserRouter,
  Route,
  Link,
  Routes
} from "react-router-dom";
import Referall from './Referall';
import FarmPage from '../FarmPage';
import StackPage from './StackPage';

const SwapPage = () => {
  return (
    <BrowserRouter>
      <AppBar />
      <Routes>
        <Route path='/' element={<HomePage />} />
        {/* <Route path='/Swap' element={<SwapPageRoot />} />
        <Route path='/Referral' element={<Referall />} />
        
        <Route path='/Farm' element={<FarmPage />} />
        <Route path='/StackPage' element={<StackPage />} /> */}
        
      </Routes>
      <AppFooter />
    </BrowserRouter>
  );
};

export default SwapPage;
