import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { styled } from "@mui/system";
import { store } from "./store/Store";
import SwapPage from "./pages/swap";
import Loader from "./components/Loder";
import { useSelector } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { RPC_URL } from "./app.config";
import { useDispatch } from "react-redux";
import { setAccountBalance } from "./store/Actions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useMediaQuery } from "@mui/material";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle'
import { createTheme, ThemeProvider } from "@material-ui/core";
import './App.css'
const MyComponent = styled("div")({
  backgroundColor: "#e9ecef",
  height: "auto",
  width: "auto",
});

function App() {
    const web3 = new Web3(new Web3.providers.HttpProvider(RPC_URL));
    const matches = useMediaQuery("(min-width:1920px)");
    const isLoading: boolean = useSelector(
        (state: any) => state.loader.isLoading,
    );
    const [themeMode, setThemeMode] = useState("dark")
    const { account } = useWeb3React();
    const dispatch = useDispatch();
    
    const darkTheme = {
        mode: "dark",
        palette: {
            text: {
                primary: "white",
            },
            background: {
                paper: "black",
                default: "grey"
            },
        },
    };
    
    const lightTheme = {
        mode: "light",
        palette: {
            text: {
                primary: "black",
            },
            background: {
                paper: "white",
                default: "grey"
            },
        },
    };
    const theme = createTheme(themeMode === "dark" ? darkTheme : lightTheme);
    //@ts-ignore
    // const Web3Modal = window?.Web3Modal?.default;

    // const providerOptions = {
    //   walletconnect: {
    //     package: WalletConnectProvider, // required
    //     options: {
    //       infuraId: "bc00870f8acd437f81ffdcf8b3b3c966", // required
    //     },
    //   },
    //   coinbasewallet: {
    //     package: CoinbaseWalletSDK, // Required
    //     options: {
    //       appName: "My Awesome App", // Required
    //       infuraId: "bc00870f8acd437f81ffdcf8b3b3c966", // Required
    //       // rpc: "", // Optional if `infuraId` is provided; otherwise it's required
    //       chainId: 1, // Optional. It defaults to 1 if not provided
    //       darkMode: false, // Optional. Use dark theme, defaults to false
    //     },
    //   },
    // };

    // const web3Modal = new Web3Modal({
    //   network: "mainnet", // optional
    //   cacheProvider: false, // optional
    //   providerOptions, // required
    //   theme: {
    //     background: "rgb(39, 49, 56)",
    //     main: "rgb(199, 199, 199)",
    //     secondary: "rgb(136, 136, 136)",
    //     border: "rgba(195, 195, 195, 0.14)",
    //     hover: "rgb(16, 26, 32)",
    //   },
    // });

    useEffect(() => {
        getEtherBalance();
    }, [account]);

    const getEtherBalance = () => {
        if (account) {
            web3.eth.getBalance(account).then((result: any) => {
                let amount = (result / 10 ** 18).toFixed(3).toString();
                console.log("amount:from app ", amount);
                dispatch(setAccountBalance(amount));
            });
        }
    };

    // const connectWalletFunc = async () => {
    //   const provider = await web3Modal.connect();
    //   const web3 = new Web3(provider);
    //   console.log("web3: ", web3);
    //   return provider;
    // };
    return (
        // <Provider store={store}>
        <ThemeProvider theme={theme}>
            <MyComponent>
                <Loader isLoading={isLoading} />
                <SwapPage />
                <ToastContainer />
            </MyComponent>
        </ThemeProvider>
        // </Provider>
    );
}

export default App;
