/* eslint-disable */
import axios from 'axios';
import { SERVER_URL } from '../config';
import { store } from '../store/Store';

const request = axios.create({
  baseURL: SERVER_URL,
  // time out on 100 milliseconds
  timeout: 100000,
});
request.interceptors.request.use(
  config => {
    // do something before request is sent
    if (store.getState()?.user?.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual LOgin
      config.headers["Authorization"] = `${store.getState()?.user?.token}`;
      // config.headers['host'] = localStorage.getItem('user');
    }
    return config;
  },
  error => {
    console.log(error);
    // do something with request error
    return Promise.reject(error);
  },
);

export default request;
