import styled from '@emotion/styled';
import React, { Component } from 'react'
import Google from '../../assets/icon/Google.svg'
import Apple from '../../assets/icon/Apple.svg'
import TradingImg from '../../assets/icon/TradingImg.png'

const Div = styled("div")({
  backgroundColor: "white",
  borderRadius: 16,
  boxShadow: "0px 20px 40px rgba(0, 0, 0, 0.1)",
  marginBottom: 60,
  padding: "2%",
  "@media (max-width: 660px)": {
    textAlign: "center",
      },
});
const Pre = styled("p")({
  fontWeight: "700",
  fontSize: "34px",
  fontFamily: "Inter",
  color: "#262626",
  lineHeight: 1,
  marginTop: 36,
  "@media (max-width: 660px)": {
    fontSize: "30px",
    textAlign: "center",
    lineHeight: '42px',
    margin:'20px auto'
  },
});
const P = styled('p')({
    fontWeight: '400',
    fontSize: '16px',
    fontFamily: 'Inter',
    color: '#200440',
    lineHeight: 1.5,
     "@media (max-width: 660px)": {
    fontSize: "10px",
  },
    
});
const Get = styled("p")({
  fontWeight: "400",
  fontSize: "16px",
  fontFamily: "Inter",
  color: "#262626",
  marginTop: 36,
  lineHeight: 1.5,
  "@media (max-width: 660px)": {
    textAlign: "center",
  },
});
const Img = styled("img")({
  height: "33px",
  width: "25%",
  "@media (max-width: 660px)": {
    width: "45%",
  },
});
const DivCol = styled("div")({
  width: "85%",
  "@media (max-width: 660px)": {
    margin: "auto auto 35px auto",
  },
});


const TradingBig = () => {
    return (
      <div className="container">
        <Div>
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <DivCol>
                <Pre>Track Your Tokens and Manage Your Positions</Pre>
                <P>
                  Download the app to manage your projects, keep track of the
                  progress and complete tasks without procastinating. Stay on
                  track and complete on time!
                </P>
                <Get>Get the App</Get>

                <Img src={Google} />
                <Img src={Apple} />
              </DivCol>
            </div>
            <div className="col-sm-12 col-md-6">
              <img src={TradingImg} className="img-fluid" />
            </div>
          </div>
        </Div>
      </div>
    );
}

export default TradingBig
