import {
  SET_ALLOWANCE,
  SET_TOKEN_BALANCE,
  SET_USD_BALANCE,
  SET_ALLOWANCE_TOKEN,
  SET_INPUT_PRICE,
  SET_BUSD_TOKEN_BALANCE,
  SET_TOKEN_CONTRACT_BALANCE,
} from "../../actionTypes";

const initialState = {
  tokenBalance: null,
  allowance: 0,
  allowanceUSD: 0,
  usdBalance: 0,
  inputPrice: 0,
  busdContractBalance: 0,
  tokenContractBalance: 0,
};
const swapReducer = (state: any = initialState, action: { type: any; payload: any }) => {
  switch (action.type) {
    case SET_TOKEN_BALANCE:
      return { ...state, tokenBalance: action.payload };
    case SET_USD_BALANCE:
      return { ...state, usdBalance: action.payload };
    case SET_ALLOWANCE:
      return { ...state, allowance: action.payload };
    case SET_ALLOWANCE_TOKEN:
      return { ...state, allowanceUSD: action.payload };
    case SET_INPUT_PRICE:
      return { ...state, inputPrice: action.payload };
    case SET_BUSD_TOKEN_BALANCE:
      return { ...state, busdContractBalance: action.payload };
    case SET_TOKEN_CONTRACT_BALANCE:
      return { ...state, tokenContractBalance: action.payload };
    default:
      return state;
  }
};

export default swapReducer;
