import * as actionTypes from '../../actionTypes';

export function onModalOpen(modal: IArticle) {
  const action: ArticleAction = {
    type: actionTypes.OPEN_MODAL,
    modal,
  };
  return (dispatch: DispatchType) => {
    dispatch(action);
  };
}
export function setAccountBalance(data:any){
  return {
    type: actionTypes.SET_ACCOUNT_BALANCE,
    payload: data,
  }
}
export function setUser(data:any){
  return {
    type: actionTypes.SET_USER,
    payload: data,
  };
}