import { Web3Provider } from "@ethersproject/providers";
import { Web3ReactProvider } from "@web3-react/core";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "./store/Store";

// import("./polyfill");
function getLibrary(provider: any) {
    const library = new Web3Provider(provider, "any");
    library.pollingInterval = 15000;
    return library;
}
const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);
root.render(
    <Web3ReactProvider getLibrary={getLibrary}>
        <Provider store={store}>
            <App />
        </Provider>
    </Web3ReactProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
