import { SET_IS_LOADING } from "../../actionTypes";

const initialState = {
  isLoading: false,
};

const loader = (state: any = initialState, action: { type: any; payload: any }) => {
  switch (action.type) {
    case SET_IS_LOADING:
      return { ...state, isLoading: action.payload };

    default:
      return state;
  }
};
export default loader;
