export const ADD_ARTICLE = "ADD_ARTICLE";
export const OPEN_MODAL = "OPEN_MODAL";
export const SELECT_TOKEN = "SELECT_TOKEN";
export const RECEIVE_TOKEN = "RECEIVE_TOKEN";
export const CONNECT_WALLET = "CONNECT_WALLET";
export const CONNET_NETWORK = "CONNET_NETWORK";
export const ALERT = "ALERT";

export const SET_TOKEN_BALANCE = "SET_TOKEN_BALANCE";
export const SET_USD_BALANCE = "SET_USD_BALANCE";
export const SET_ALLOWANCE = "SET_ALLOWANCE";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_ACCOUNT_BALANCE = "SET_ACCOUNT_BALANCE";
export const SET_ALLOWANCE_TOKEN = "SET_ALLOWANCE_TOKEN";
export const SET_INPUT_PRICE = 'SET_INPUT_PRICE';
export const SET_BUSD_TOKEN_BALANCE = 'SET_BUSD_TOKEN_BALANCE';
export const SET_TOKEN_CONTRACT_BALANCE = 'SET_TOKEN_CONTRACT_BALANCE'
export const SET_USER = "SET_USER";