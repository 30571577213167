// ** Redux Imports
import { combineReducers } from 'redux';

// ** Reducers Imports
import reducer from './reducer';
import swapReducer from './Swap'
import loader from './Loader';
import user from '../Reducers/User';

const rootReducer = combineReducers({
  reducer,
  swapReducer,
  loader,
  user,
});

export default rootReducer;
