// this file is a AppFooter file and Create a Footer bar 
import styled from '@emotion/styled';
import React, { Component, useState } from 'react';
import discord from '../../assets/icon/discord.png';
import social from '../../assets/icon/social.png';
import teligram from '../../assets/icon/teligram.svg';
import teligramWhite from '../../assets/icon/teligramWhite.svg';
import twiter from '../../assets/icon/twiter.svg';
import TwitterBlack from '../../assets/icon/TwitterBlack.svg';

import facebook from '../../assets/icon/facebook.svg';
import facebookWhite from '../../assets/icon/facebookWhite.svg';
import instagram from '../../assets/icon/instagram.svg';
import InstaWhite from '../../assets/icon/InstaWhite.svg';

import LinkDinWhite from '../../assets/icon/LinkDinWhite.svg';

import reddit from '../../assets/icon/reddit.svg';
import redditWhite from '../../assets/icon/redditWhite.svg';

import LinkDin from '../../assets/icon/LinkDin.svg';
import { Button, Grid } from '@mui/material';
import Logo from '../../assets/logof.png';
import useMediaQuery from '@mui/material/useMediaQuery';

const MainComponent = styled(Grid)({
  display: 'flex',
  paddingLeft: 30,
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingRight: 30,
  boxShadow: '0px 10px 5px #888, -1px -5px 20px 0px #C4C4C4'
});
const CustomButtom = styled('button')({
  borderRadius: "100%",
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
});
const Main = styled("div")({
  display: 'grid',
  alignItems: 'center',
  textAlign: 'center', 
  // marginLeft: '50%'
}); 
const MainDiv = styled("div")({
  // marginLeft:55
});
const MainFlex = styled("div")({
  display: 'flex',
  alignItems: 'center',
});
const NameButton = styled("span")({
  margin: 10,
  marginRight: 20,
  backgroundColor: 'transparent',
  padding: '10px 10px',
  color: '#706e6be8',
  fontFamily: 'Inter',
  fontWeight: 600,
  fontSize: 16,
});
const NameButton2 = styled("span")({
  margin: 5,
  backgroundColor: 'transparent',
  padding: '10px 10px',
  color: '#706e6be8',
  fontFamily: 'Inter',
  fontWeight: 600,
  fontSize: 16,
  textAlign: 'center'
});
const CustomButtomActive = styled(Button)({
  height: "60px",
  width: "52px",
  borderRadius: "100%",
  margin: 7,
  background: 'linear-gradient(45deg, #5e6bfb 0%, #5e6bfb75 100%)',
  color: '#000000',
});
const Img = styled('img')({
  height: "52px",
  width: "52px",
});

const AppFooter = () => {
  const matches = useMediaQuery('(min-width:1000px)');
  const [Twiter, settwiter] = useState(false)
  const [Insta, setInsta] = useState(false)
  const [linkdin, setLinkdin] = useState(false)
  const [Facebook, setFacebook] = useState(false)
  const [Reddit, setReddit] = useState(false)
  const [Teligram, setTeligram] = useState(false)
  const matches1920 = useMediaQuery("(min-width:1920px)");

  return (
    <MainComponent
      container={matches ? false : true}
      style={{
        paddingLeft: matches1920 ? 200 : 50,
        paddingRight: matches1920 ? 200 : 50,
      }}
    >
      <Grid item style={{ margin: "auto" }} sm={12} md={6}>
        {matches === true ? (
          <MainFlex>
            <NameButton>
              <img src={Logo} height="100px" width="100px" />
            </NameButton>
            <NameButton></NameButton>
            <NameButton></NameButton>
            <NameButton></NameButton>
            {/* <NameButton> Paraacrypto@2024</NameButton> */}
            {/* <NameButton>Whitepaper</NameButton> */}
            {/* <NameButton>Documentation</NameButton> */}
            {/* <NameButton>Github</NameButton> */}
            {/* <NameButton>Help</NameButton> */}
          </MainFlex>
        ) : (
          <Main>
            <NameButton2>
              <img src={Logo} height="100px" width="100px" />
            </NameButton2>
            {/* <NameButton2>RPX</NameButton2>
            <NameButton2>Whitepaper</NameButton2>
            <NameButton2>Documentation</NameButton2>
            <NameButton2>Github</NameButton2>
            <NameButton2>Help</NameButton2> */}
            {/* <NameButton></NameButton>
            <NameButton></NameButton>
            <NameButton></NameButton> */}
            {/* <NameButton> Paraacrypto@2024</NameButton> */}
          </Main>
        )}
      </Grid>
      <Grid item style={{ margin: "auto" }} sm={12} md={6}>
        {matches === true ? (
          <div>
            <CustomButtom
              onMouseEnter={() => {
                settwiter(true);
              }}
              onMouseLeave={() => {
                settwiter(false);
              }}
            >
              <Img src={Twiter ? twiter : TwitterBlack} alt="twiter" />
            </CustomButtom>
            <CustomButtom
              onMouseEnter={() => {
                setLinkdin(true);
              }}
              onMouseLeave={() => {
                setLinkdin(false);
              }}
            >
              <Img src={linkdin ? LinkDinWhite : LinkDin} alt="social" />
            </CustomButtom>
            <CustomButtom
              onMouseEnter={() => {
                setFacebook(true);
              }}
              onMouseLeave={() => {
                setFacebook(false);
              }}
            >
              <Img src={Facebook ? facebookWhite : facebook} alt="teligram" />
            </CustomButtom>
            <CustomButtom
              onMouseEnter={() => {
                setInsta(true);
              }}
              onMouseLeave={() => {
                setInsta(false);
              }}
            >
              <Img src={Insta ? InstaWhite : instagram} alt="discord" />
            </CustomButtom>
            <CustomButtom
              onMouseEnter={() => {
                setReddit(true);
              }}
              onMouseLeave={() => {
                setReddit(false);
              }}
            >
              <Img src={Reddit ? redditWhite : reddit} alt="discord" />
            </CustomButtom>
            <CustomButtom
              onMouseEnter={() => {
                setTeligram(true);
              }}
              onMouseLeave={() => {
                setTeligram(false);
              }}
            >
              <Img src={Teligram ? teligramWhite : teligram} alt="discord" />
            </CustomButtom>
          </div>
        ) : (
          <MainDiv style={{ textAlign: "center" }}>
            <CustomButtom
              onMouseEnter={() => {
                settwiter(true);
              }}
              onMouseLeave={() => {
                settwiter(false);
              }}
            >
              <Img src={Twiter ? twiter : TwitterBlack} alt="twiter" />
            </CustomButtom>
            <CustomButtom
              onMouseEnter={() => {
                setLinkdin(true);
              }}
              onMouseLeave={() => {
                setLinkdin(false);
              }}
            >
              <Img src={linkdin ? LinkDinWhite : LinkDin} alt="social" />
            </CustomButtom>
            <CustomButtom
              onMouseEnter={() => {
                setFacebook(true);
              }}
              onMouseLeave={() => {
                setFacebook(false);
              }}
            >
              <Img src={Facebook ? facebookWhite : facebook} alt="teligram" />
            </CustomButtom>
            <br />
            <CustomButtom
              onMouseEnter={() => {
                setInsta(true);
              }}
              onMouseLeave={() => {
                setInsta(false);
              }}
            >
              <Img src={Insta ? InstaWhite : instagram} alt="discord" />
            </CustomButtom>
            <CustomButtom
              onMouseEnter={() => {
                setReddit(true);
              }}
              onMouseLeave={() => {
                setReddit(false);
              }}
            >
              <Img src={Reddit ? redditWhite : reddit} alt="discord" />
            </CustomButtom>
            <CustomButtom
              onMouseEnter={() => {
                setTeligram(true);
              }}
              onMouseLeave={() => {
                setTeligram(false);
              }}
            >
              <Img src={Teligram ? teligramWhite : teligram} alt="discord" />
            </CustomButtom>
          </MainDiv>
        )}
      </Grid>
    </MainComponent>
  );
};

export default AppFooter;
