import styled from "@emotion/styled";
import React, { Component, useEffect, useState } from "react";
import BitCoin from "../../assets/icon/BitCoin.png";
import Right from "../../assets/icon/right.png";
import Left from "../../assets/icon/Left.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useMediaQuery } from "@mui/material";
import ItemsCarousel from "react-items-carousel";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 600 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
  },
};

const CardMain = styled("div")({
  backgroundColor: "white",
  borderRadius: 20,
  width: "100%",
  padding: 15,
  marginBottom: 20,
  "@media (max-width: 660px)": {
    width: "100%",
  },
});
const Div = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});
const Price = styled("p")({
  fontSize: "16px",
  fontWeight: "600",
  fontFamily: "Inter",
  color: "#11C9BE",
});
const Title = styled("p")({
  fontSize: "16px",
  fontWeight: "600",
  fontFamily: "Inter",
  color: "#262626",
  marginTop: 2,
});
const PriceTotal = styled("span")({
  fontSize: "24px",
  fontWeight: "700",
  fontFamily: "Inter",
  color: "#262626",
});
const Type = styled("span")({
  fontSize: "16px",
  fontWeight: "400",
  fontFamily: "Inter",
  color: "#4B4B4B",
  marginRight: 10,
});
const View = styled("p")({
  fontSize: "18px",
  fontWeight: "600",
  fontFamily: "Inter",
  color: "#5D5FEF",
  textAlign: "end",
});
const ImgSlid = styled("img")({
  marginTop: "6%",
  marginLeft: 30,
  marginRight: 30,
  "@media (max-width: 660px)": {
    marginLeft: 10,
    marginRight: 10,
    marginTop: "82%",
  },
});

const DivMain = styled("div")({
  "@media (min-width: 660px)": {
    marginTop: "100px",
    marginBottom: "80px",
    // padding:'0px 20px'
  },
  // "@media (max-width: 660px)": {
  //   marginTop: "450px",
  //   marginBottom: "50px",
  // },
});

const CoinCard = () => {
  const matches = useMediaQuery("(max-width:600px)");
  const matches1 = useMediaQuery("(max-width:1000px)");

  const CustomButtonGroup = ({ next, previous, goToSlide, carouselState }) => {
    const { totalItems, currentSlide } = carouselState;
    return (
      <div className="custom-button-group" style={{ position: "absolute" }}>
        {/* <div>Current slide is {currentSlide}</div> */}
        <button onClick={() => previous()}>Previous slide</button>
        <button onClick={() => next()}>Next slide</button>
        <button
          onClick={() => goToSlide(Math.floor(Math.random() * totalItems + 1))}
        >
          Go to a random slide
        </button>
      </div>
    );
  };
  const CustomRightArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType },
    } = rest;
    // onMove means if dragging or swiping in progress.
    return <button onClick={() => onClick()}>right</button>;
  };
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 35;
  const [show, setShow] = useState(false);
  useEffect(() => {
    const widgetContainer = document.getElementById(
      "coincard"
    );
    const widget = document.getElementById(
      "coinmarketcap-widget-coin-price-block"
    );
    widgetContainer.appendChild(widget);
  }, [""]);
  return (
    <DivMain
      className="container"
      style={{
        padding: 0,
      }}
    >
      <div>
        {/* <div className="tradingview-widget-container"> */}
        {/* <div
              id="coinmarketcap-widget-coin-price-block"
              coins="1,1027,825,1839,3513,2010,4687"
              currency="INR"
              theme="light"
              transparent="false"
              show-symbol-logo="true"
            ></div> */}
        {/* </div> */}
        <div
          id="coincard"
          // coins="1,1027,825"
          // currency="USD"
          // theme="light"
          // transparent="false"
          // show-symbol-logo="true"
        ></div>
      </div>
      {/* <div style={{ padding: `0 ${chevronWidth}px` }}>
        <ItemsCarousel
          requestToChangeActive={setActiveItemIndex}
          activeItemIndex={activeItemIndex}
          numberOfCards={matches ? 1 : matches1 ? 2 : 4}
          gutter={20}
          leftChevron={<span style={{ fontSize: 25 }}> &lt; </span>}
          rightChevron={<span style={{ fontSize: 25 }}>&gt;</span>}
          outsideChevron
          chevronWidth={chevronWidth}
        >
          <div style={{ zIndex: 999 }}>
            <CardMain>
              <Div>
                <img src={BitCoin} width="40px" height={40} />
                <Price>+1.38%</Price>
              </Div>
              <Title>Bitcoin</Title>
              <Div>
                <PriceTotal>$41,258.40</PriceTotal>
                <Type>BTC</Type>
              </Div>
            </CardMain>
          </div>
          <div style={{ zIndex: 999 }}>
            <CardMain>
              <Div>
                <img src={BitCoin} width="40px" height={40} />
                <Price>+1.38%</Price>
              </Div>
              <Title>Bitcoin</Title>
              <Div>
                <PriceTotal>$41,258.40</PriceTotal>
                <Type>BTC</Type>
              </Div>
            </CardMain>
          </div>
          <div style={{ zIndex: 999 }}>
            <CardMain>
              <Div>
                <img src={BitCoin} width="40px" height={40} />
                <Price>+1.38%</Price>
              </Div>
              <Title>Bitcoin</Title>
              <Div>
                <PriceTotal>$41,258.40</PriceTotal>
                <Type>BTC</Type>
              </Div>
            </CardMain>
          </div>
          <div style={{ zIndex: 999 }}>
            <CardMain>
              <Div>
                <img src={BitCoin} width="40px" height={40} />
                <Price>+1.38%</Price>
              </Div>
              <Title>Bitcoin</Title>
              <Div>
                <PriceTotal>$41,258.40</PriceTotal>
                <Type>BTC</Type>
              </Div>
            </CardMain>
          </div>
          <div style={{ zIndex: 999 }}>
            <CardMain>
              <Div>
                <img src={BitCoin} width="40px" height={40} />
                <Price>+1.38%</Price>
              </Div>
              <Title>Bitcoin</Title>
              <Div>
                <PriceTotal>$41,258.40</PriceTotal>
                <Type>BTC</Type>
              </Div>
            </CardMain>
          </div>
          <div style={{ zIndex: 999 }}>
            <CardMain>
              <Div>
                <img src={BitCoin} width="40px" height={40} />
                <Price>+1.38%</Price>
              </Div>
              <Title>Bitcoin</Title>
              <Div>
                <PriceTotal>$41,258.40</PriceTotal>
                <Type>BTC</Type>
              </Div>
            </CardMain>
          </div>
          <div style={{ zIndex: 999 }}>
            <CardMain>
              <Div>
                <img src={BitCoin} width="40px" height={40} />
                <Price>+1.38%</Price>
              </Div>
              <Title>Bitcoin</Title>
              <Div>
                <PriceTotal>$41,258.40</PriceTotal>
                <Type>BTC</Type>
              </Div>
            </CardMain>
          </div>
        </ItemsCarousel>
      </div> */}

      {/* <Carousel
        // style={{ display: "none" }}
        //   swipeable={true}
        //   draggable={false}
        arrows={!matches}
        showDots={matches}
        responsive={responsive}
        //   slidesToSlide={1}
        //   ssr={false} // means to render carousel on server-side.
        infinite={true}
        //   autoPlaySpeed={1000}
        //   keyBoardControl={true}
        //   customTransition="all 1"
        //   transitionDuration={1000}
        //   containerClass="carousel-container"
        //   dotListClass="custom-dot-list-style"
        //   itemClass="carousel-item-padding-40-px"
        // customButtonGroup={<CustomButtonGroup />}
        // customRightArrow={<CustomRightArrow />}
        // customButtonGroup={<CustomButtonGroup />}
      >
        <div style={{ zIndex: 999 }}>
          <CardMain>
            <Div>
              <img src={BitCoin} width="40px" height={40} />
              <Price>+1.38%</Price>
            </Div>
            <Title>Bitcoin</Title>
            <Div>
              <PriceTotal>$41,258.40</PriceTotal>
              <Type>BTC</Type>
            </Div>
          </CardMain>
        </div>

        <div style={{ zIndex: 999 }}>
          <CardMain>
            <Div>
              <img src={BitCoin} width="40px" height={40} />
              <Price>+1.38%</Price>
            </Div>
            <Title>Bitcoin</Title>
            <Div>
              <PriceTotal>$41,258.40</PriceTotal>
              <Type>BTC</Type>
            </Div>
          </CardMain>
        </div>

        <div style={{ zIndex: 999 }}>
          <CardMain>
            <Div>
              <img src={BitCoin} width="40px" height={40} />
              <Price>+1.38%</Price>
            </Div>
            <Title>Bitcoin</Title>
            <Div>
              <PriceTotal>$41,258.40</PriceTotal>
              <Type>BTC</Type>
            </Div>
          </CardMain>
        </div>

        <div style={{ zIndex: 999 }}>
          <CardMain>
            <Div>
              <img src={BitCoin} width="40px" height={40} />
              <Price>+1.38%</Price>
            </Div>
            <Title>Bitcoin</Title>
            <Div>
              <PriceTotal>$41,258.40</PriceTotal>
              <Type>BTC</Type>
            </Div>
          </CardMain>
        </div>

        <div style={{ zIndex: 999 }}>
          <CardMain>
            <Div>
              <img src={BitCoin} width="40px" height={40} />
              <Price>+1.38%</Price>
            </Div>
            <Title>Bitcoinssss</Title>
            <Div>
              <PriceTotal>$41,258.40</PriceTotal>
              <Type>BTC</Type>
            </Div>
          </CardMain>
        </div>
      </Carousel> */}
    </DivMain>
  );
};

export default CoinCard;
