import Coin from "../assets/icon/coin.png";
import binance from "../assets/icon/binance.png";
import l from "../assets/icon/l.png";
import cardanoada from "../assets/icon/cardanoada.png";
import chainlinklink from "../assets/icon/chainlinklink.png";
import Coin2 from "../assets/icon/coin2.png";
import Coin3 from "../assets/icon/coin3.png";
import Coin4 from "../assets/icon/coin4.png";
import gnosis from "../assets/icon/gnosis.png";
import arbitrum from "../assets/icon/arbitrum.png";
import bsc from "../assets/icon/bsc.png";
import metamask from "../assets/icon/metamask.png";
import coinbase from "../assets/icon/coinbase.png";
import wallet from "../assets/icon/wallet.png";
import coin6 from "../assets/icon/coin6.png";
import fortmatic from "../assets/icon/fortmatic.png";
import arkane from "../assets/icon/arkane.png";
import mew from "../assets/icon/mew.png";
import TwT from "../assets/icon/TWT.png";
export const DropDownData = [
  {
    name: "Etherum",
    coin: Coin,
  },
  {
    name: "Avalanche",
    coin: Coin2,
  },
  {
    name: "Binance",
    coin: binance,
  },
  {
    name: "Polygon",
    coin: Coin4,
  },
  {
    name: "Gnosis",
    coin: gnosis,
  },
  {
    name: "Arbitrum",
    coin: arbitrum,
  },
];
export const DropDownDataWallet = [
  {
    Subname: "36.34 USDT",
    Price: "$156.26",
    coin: Coin3,
  },
  {
    Subname: "34.05 BNB",
    Price: "$56.26",
    coin: binance,
  },
  {
    Subname: "125.67 LTC",
    Price: "$25.26",
    coin: l,
  },
  {
    Subname: "36.34 ADA",
    Price: "$57.26",
    coin: cardanoada,
  },
  {
    Subname: "1.56 LINK",
    Price: "$23.26",
    coin: chainlinklink,
  },
];
export const ConnectNetWorkOne = [
  {
    name: "Etherum",
    coin: Coin,
  },
  // {
  //   name: 'Avalanche',
  //   coin: Coin2,
  // },
  {
    name: "Binance",
    coin: binance,
  },
  {
    name: "Polygon",
    coin: Coin4,
  },
  // {
  //   name: 'Gnosis',
  //   coin: gnosis,
  // },
  // {
  //   name: 'Arbitrum',
  //   coin: arbitrum,
  // },
];

export const ConnectWalletOne = [
  {
    name: "BSC",
    coin: bsc,
    Subname: "34.05 BNB",
    Price: "$56.26",
  },
  {
    name: "MetaMask",
    coin: metamask,
    Subname: "34.001 BNB",
    Price: "$147.26",
  },
  // {
  //   name: 'Coinbase',
  //   coin: coinbase,
  //   Subname: '32.20 BNB',
  //   Price: '$59.26',
  // },
  {
    name: "Wallet Connect",
    coin: wallet,
    Subname: "35.45 BNB",
    Price: "$145.20",
  },
  // {
  //   name: '1inch',
  //   coin: coin6,
  //   Subname: '858.14 BNB',
  //   Price: '$152.4745',
  // },
  // {
  //   name: 'Formatic',
  //   coin: fortmatic,
  //   Subname: '1745.1485 BNB',
  //   Price: '$652.0045',
  // },
  // {
  //   name: 'Arkane',
  //   coin: arkane,
  //   Subname: '75814.4158 BNB',
  //   Price: '$14785.17452',
  // },
  // {
  //   name: 'MEW',
  //   coin: mew,
  //   Subname: '14758.05 BNB',
  //   Price: '$56.02600',
  // },
  {
    name: "Trust Wallet",
    coin: TwT,
    Subname: "14758.05 BNB",
    Price: "$56.02600",
  },
];
