import styled from "@emotion/styled";
import React, { Component } from "react";
import processor from "../../assets/icon/Illustration 2.png";
import globe from "../../assets/icon/globe.png";
import { Navigate } from "react-router-dom";

const Pre = styled("p")({
  fontWeight: "700",
  fontSize: "48px",
  fontFamily: "Inter",
  color: "#262626",
  marginTop: "0%",
  textAlign: "center",
  lineHeight: 1,
  "@media (max-width: 660px)": {
    fontSize: "32px",
    marginTop: 0,
  },
});
const P = styled("p")({
  fontWeight: "600",
  fontSize: "18px",
  fontFamily: "Inter",
  color: "#262626",
  textAlign: "center",
  lineHeight: 1,
  marginBottom: 60,
});
const Title = styled("p")({
  fontWeight: "700",
  fontSize: "20px",
  fontFamily: "Inter",
  color: "#262626",
  paddingLeft: "5%",
  paddingTop: "22%",
  lineHeight: 1.3,
  maxWidth: 280,
  "@media (max-width: 660px)": {
    fontSize: "14px",
  },
});

const Card = styled("div")({
  backgroundColor: "white",
  borderRadius: 16,
  boxShadow: "0px 20px 40px rgba(0, 0, 0, 0.1)",
  display: "flex",
  marginBottom: 60,
  height: 280,
  width: "98%",
  "@media (max-width: 660px)": {
    height: 180,
    marginBottom: 30,
  },
});
const Btn = styled("button")({
  fontWeight: "700",
  fontSize: "18px",
  fontFamily: "Inter",
  color: "#262626",
  textAlign: "center",
  border: "none",
  borderRadius: 16,
  background: "linear-gradient(45deg, #5e6bfb 0%, #5e6bfb75 100%)",
  padding: 8,
  paddingLeft: 25,
  paddingRight: 25,
  marginLeft: "5%",
  marginTop: "5%",
  "@media (max-width: 660px)": {
    fontSize: "12px",
    padding: "5px 14px",
  },
});
const Img = styled("img")({
  height: "100%",
  width: "100%",
  "@media (max-width: 660px)": {
    height: "auto",
    // width: "80%",
  },
});

const Trading = () => {
  return (
    <div className="container">
      <Pre>Your Trading Home</Pre>
      {/* <P>Pellentesque nunc ac interdum neque in purus in pharetra</P> */}
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-6">
          <Card>
            <div className="d-flex">
              <div>
                <Title>Track Your Tokens and Manage Your Positions</Title>
                <Btn>See Your Portfolio</Btn>
              </div>
              <a target={"_blank"} href="https://app.paraacrypto.com/">
                <Img src={processor} />
              </a>
            </div>
          </Card>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6">
          <Card>
            <div className="d-flex">
              <div>
                <Title>Ready to Start Trading CryptoCurrency?</Title>

                <a target={"_blank"} href="https://app.paraacrypto.com/">
                  {" "}
                  <Btn>Start Trading</Btn>
                </a>
              </div>
              <a target={"_blank"} href="#">
                <Img src={globe} />
              </a>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Trading;
