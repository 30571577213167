/* eslint-disable no-shadow */
/* eslint-disable no-async-promise-executor */
import request from './request';

export const connectAccount = payload => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("user/auth", payload)
      .then((data) => {
        resolve(data.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getReferralData = () => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("user/user/referral-tree")
      .then((data) => {
        resolve(data.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const addRefCode = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("user/auth/referral",body)
      .then((data) => {
        resolve(data.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};