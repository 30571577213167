import React, { Component } from 'react'
import About from '../LendingPage/About'
import CoinCard from '../LendingPage/CoinCard'
import Connect from '../LendingPage/Connect'
import Funnel from '../LendingPage/Funnel'
import Over from '../LendingPage/Over'
import Portfolio from '../LendingPage/Portfolio'
import Ruls from '../LendingPage/Ruls'
import Sell from '../LendingPage/Sell'
import Trading from '../LendingPage/Trading'
import TradingBig from '../LendingPage/TradingBig'
import heroBanner from "../assets/icon/HeroBanner.png";
import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { Container } from '@mui/system'
import Withdraw from '../LendingPage/Withdraw'

const HomeDiv = styled('div')({
  backgroundImage: `url(${heroBanner})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "right 0px top 0px",
  backgroundSize: "auto",
});
const HomePage = () => {
    return (
      <HomeDiv>
        <Container>
          <About />
          <CoinCard />
          {/* <Portfolio /> */}
          {/* <Funnel /> */}
          {/* <Ruls /> */}
          {/* <Sell /> */}
          <Over />
          <Trading />
          <TradingBig />
          {/* <Connect /> */}
        </Container>
      </HomeDiv>
    );
}


export default HomePage