import { SET_ACCOUNT_BALANCE, SET_USER } from "../../actionTypes"

const initialState = {
    accountBalance: 0,
    user:null,
    token:null
}

const user = (state = initialState, action: { type:any, payload:any }) => {
  switch (action.type) {
    case SET_ACCOUNT_BALANCE:
      return { ...state, accountBalance: action.payload };
    case SET_USER:
      return { ...state,user: action.payload.user,token: action.payload.token };
    default:
      return state;
  }
}
export default user;
